/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Link } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'
import ButtonAlt from 'components/elements/Button/ButtonAlt'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'

const StyledCol = styled(motion.div)`
  &:first-of-type {
    padding: 25px 25px 25px 15px;
  }

  &:last-of-type {
    padding: 25px 15px 25px 25px;
  }

  padding: 25px 25px 25px 25px;

  @media screen and (max-width: 1200px) {
    width: 194px;

    &:first-of-type {
      padding: 25px 25px 25px 25px;
    }

    &:last-of-type {
      padding: 25px 25px 25px 25px;
    }
  }

  @media screen and (max-width: 991px) {
    width: unset;

    &:first-of-type {
      padding: 25px 25px 25px 25px;
    }

    &:last-of-type {
      padding: 25px 25px 25px 25px;
    }
  }

  @media screen and (max-width: 767px) {
    width: unset;
    padding: 15px 15px 50px 15px !important;
  }
`

const Round = styled(motion.div)`
  height: 15px;
  width: 15px;
  border: 3px solid ${(props) => props.theme.color.face.contrast};
  border-radius: 8px;
  margin: 0 10px 0 0;
`

const Title = styled.div`
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.font.weight.xl};
  font-size: ${(props) => props.theme.font.size[20]};
  line-height: 20px;
  color: ${(props) => props.theme.color.text.main};

  &:hover {
    color: ${(props) => props.theme.color.text.main};
  }
`

const StyledImage = styled(Plaatjie)`
  object-fit: contain;
  height: 100%;
  position: absolute !important;
  border-radius: 15px;
  width: 100%;

  & img {
    border-radius: 15px;
  }

  @media screen and (max-width: 400px) {
    border-radius: 8px;

    & img {
      border-radius: 8px;
    }
  }
`

const Wrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  min-height: 139px;
  border-radius: 15px;

  @media screen and (max-width: 400px) {
    min-height: 100px;
    border-radius: 8px;
  }
`

interface ApplicationsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Applications
}

const Applications: React.FC<ApplicationsProps> = ({ fields }) => (
  <div className="py-5">
    <div className="container py-lg-5">
      <ParseContent content={fields?.description} />
      <div className="row">
        {fields?.applications?.map((application: any, index: number) => (
          <StyledCol
            initial="init"
            whileHover="hover"
            className={`pb-5 ${
              (fields?.applications?.length || 0) >= 6 ? `col-lg-4` : `col-lg`
            } col-md-4 col-6`}
            key={index}
          >
            <Link to={application.link.url}>
              <Wrapper
                className="position-relative"
                variants={{
                  hover: { boxShadow: '0 0 10px rgba(0,0,0,0.3)' },
                }}
              >
                <StyledImage image={application.image} alt="" loading="eager" />
              </Wrapper>
              <div className="pt-sm-4 pt-3 text-center d-flex justify-content-center align-items-center">
                <Round
                  variants={{
                    init: { y: 0 },
                    hover: {
                      y: [0, -10, 0, -10, 0],
                      transition: { repeat: Infinity, duration: 2 },
                    },
                  }}
                />
                <Title>{application?.link?.title}</Title>
              </div>
            </Link>
          </StyledCol>
        ))}
      </div>

      {fields?.link?.url && (
        <div className="d-flex justify-content-center pt-5 mt-3">
          <ButtonAlt to={fields?.link?.url}>{fields?.link?.title}</ButtonAlt>
        </div>
      )}
    </div>
  </div>
)

export default Applications
